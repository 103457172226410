<template>
    <div class="sale-wrapper center-wrapper">
        <div class="side-wrap">
            <TableSideBar :items="sideBarData" />
          </div>
        <div class="main-content">
            <div>
                <div class="outer">
                    <div class="inner">
                        <span>
                            Agent Name
                        </span>
                        <span v-if="salesAgent['fullname']" class="name">
                            {{ salesAgent['fullname'] }}
                        </span>
                    </div>
                </div>
                <div class="outer">
                    <div class="inner">
                        <span>
                            Email ID
                        </span>
                        <span v-if="salesAgent['email']">
                            {{ salesAgent['email'] }}
                        </span>
                    </div>
                </div>
                <div class="outer">
                    <div class="inner">
                        <span>
                            Contact Number
                        </span>
                        <span v-if="salesAgent['phoneNumber']">
                            {{ salesAgent['phoneNumber'] }}
                        </span>
                    </div>
                </div>
                <div v-if="salesAgent['personal_detail'] && salesAgent['personal_detail']['trade_info'] && salesAgent['personal_detail']['trade_info'].length > 0">
                    <div v-for="(trade, index) in salesAgent['personal_detail']['trade_info']" :key="index">
                        <div class="outer">
                            <div class="inner">
                                <span>
                                    Company Contact Details
                                </span>
                                <span v-if="trade['company']">
                                    {{ trade['company'] }}
                                </span>
                            </div>
                        </div>
                        <div class="outer">
                            <div class="inner">
                                <span>
                                    Company Email ID
                                </span>
                                <span>
                                    <p v-if="trade['contact']">{{ trade['contact'] }}
                                    </p>
                                    <p v-if="trade['phone']">{{ trade['phone'] }}
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TableSideBar from '@/components/molecules/TableSideBar'
import Common from '@/mixins/Common'
export default {
    mixins: [Common],
    components: {
        TableSideBar
    },
    metaInfo() {
      return {
        title: 'Your Sales Agent'
      }
    },
    computed: {
        ...mapState('auth', ['userDetails']),
        ...mapState('salesrep', ['salesAgent'])
    },
    watch: {
        userDetails() {
            this.fetchSalesRep()
        }
    },
    mounted() {
        if (this.userDetails && this.userDetails.id) {
            this.fetchSalesRep()
        }
    },
    methods: {
        fetchSalesRep() {
          this.$store.dispatch('salesrep/fetchSalesRep', { userId: this.userDetails.id })
        }
    }
}
</script>

<style lang="sass" scoped>
.sale-wrapper
  display: flex
  padding: 5rem 0
  .side-wrap
    flex: 0.4
  .main-content
    display: flex
    justify-content: center
    flex: 1
    flex-direction: column
    padding: 0 15rem
  .inner
    display: flex
    justify-content: space-between
    margin-bottom: 0.3rem
    @include font-source('Poppins', 13px, 600, $black1)
    span
      &:first-child
        font-weight: 600
      &:last-child
        font-weight: 400
      &.name
        font-weight: 600
        font-size: 15px
        text-transform: uppercase
      p
        margin-bottom: 0.3rem
        font-size: 13px
</style>